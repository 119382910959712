<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.weight_config") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 305px)"
        class="table-padding-2"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" style="width: 130px">
                <SelectWarehouse
                  :label="$t('labels.warehouse')"
                  :placeholder="$t('labels.warehouse')"
                  name="id_warehouse"
                  sort-name="warehouse_code"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                  :auto-select="false"
                />
              </th>
              <th role="columnheader" style="width: 130px">
                <SelectPos
                  :label="$t('labels.pos')"
                  :placeholder="$t('labels.pos')"
                  name="id_pos"
                  sort-name="pos_code"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                  :auto-select="false"
                />
              </th>
              <th role="columnheader">
                <InputFilterFromTo
                  :label="$t('labels.max_weight')"
                  :placeholder="$t('labels.max_weight')"
                  name="max_weight"
                  sort-name="max_weight"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="width: 150px">
                <SelectFilter
                  :options="deliveryOptions"
                  :label="$t('labels.delivery_company')"
                  :placeholder="$t('labels.delivery_company')"
                  name="id_delivery_contract"
                  @onFilter="onFilterChange"
                />
              </th>
              <th role="columnheader" style="width: 130px">
                <SelectFilter
                  :options="typeOptions"
                  :label="$t('labels.type')"
                  :placeholder="$t('labels.type')"
                  name="type"
                  @onFilter="onFilterChange"
                />
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="min-width: 98px"
              >
                <v-btn
                  small
                  color="primary"
                  @click="addItem"
                  :disabled="isDisabledBtnAdd"
                >
                  {{ $t("labels.add") }}
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(item, key) in items"
              :key="`dc_${item.id}`"
            >
              <td>
                <span v-if="!item.editing || !!item.id">{{
                  item.warehouse_code
                }}</span>
                <select-warehouse-model
                  v-else
                  v-model="item.id_warehouse"
                  single-line
                  :disabled="!!item.id_pos"
                  label=""
                ></select-warehouse-model>
              </td>
              <td>
                <span v-if="!item.editing || !!item.id">{{
                  item.pos_code
                }}</span>
                <select-pos-model
                  v-else
                  v-model="item.id_pos"
                  single-line
                  :disabled="!!item.id_warehouse"
                  label=""
                ></select-pos-model>
              </td>
              <td>
                <span v-if="!item.editing"
                  >{{ formatNumber(item.max_weight) }} g</span
                >
                <input-number
                  v-else
                  v-model.number="item.max_weight"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class="c-input-small"
                />
              </td>
              <td>
                <span v-if="!item.editing">{{
                  item.delivery_company_short_name
                }}</span>
                <v-autocomplete
                  v-else
                  class="c-input-small"
                  v-model="item.id_delivery_contract"
                  :items="deliveryItemOptions(item)"
                  :disabled="
                    !item.editing || (!item.id_warehouse && !item.id_pos)
                  "
                  :label="$t('labels.delivery_company')"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <span v-if="!item.editing">{{
                  $t(`labels.delivery_type_${item.type}`)
                }}</span>
                <v-autocomplete
                  v-else
                  class="c-input-small"
                  v-model="item.type"
                  :items="typeOptions"
                  :disabled="!item.editing || !!item.id"
                  :label="$t('labels.type')"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <template v-if="!item.editing">
                  <v-btn
                    x-small
                    color="warning"
                    @click="toggleEditing(item, key, true)"
                    >{{ $t("labels.edit") }}</v-btn
                  >
                </template>
                <template v-else>
                  <v-btn
                    class="mr-1"
                    x-small
                    color="success"
                    @click="saveConfig(item)"
                  >
                    {{ $t("labels.save") }}
                  </v-btn>
                  <v-btn
                    x-small
                    color="error"
                    @click="toggleEditing(item, key, false)"
                  >
                    {{ $t("labels.cancel") }}
                  </v-btn>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div>
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  YES_NO_OPTIONS,
  DELIVERY_TYPE_INTER_PROVINCIAL,
  DELIVERY_TYPE_PROVINCIAL,
} from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "DeliveryContract",
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectPos: () => import("@/components/table/SelectPos"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
  },
  props: {
    contracts: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    filters: {},
    isLoading: false,
    page: 1,
    totalPage: 1,
    totalItem: 0,
    items: [],
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    deliveryOptions() {
      return [...this.contracts].map((item) => ({
        text: item.delivery_company_short_name,
        value: item.id,
        id_pos: item.id_pos,
        id_warehouse: item.id_warehouse,
      }));
    },
    deliveryItemOptions() {
      return (item) =>
        [...this.deliveryOptions]
          .filter(
            (type) =>
              type.id_pos === +item.id_pos &&
              type.id_warehouse === +item.id_warehouse
          )
          .map((jtem) => ({
            text: jtem.text,
            value: jtem.value,
          }));
    },
    typeOptions() {
      return [
        {
          value: DELIVERY_TYPE_PROVINCIAL,
          text: "Nội tỉnh",
        },
        {
          value: DELIVERY_TYPE_INTER_PROVINCIAL,
          text: "Liên tỉnh",
        },
      ];
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    cancel() {
      this.$emit("cancel");
    },
    addItem() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        id_warehouse: null,
        id_pos: null,
        id_delivery_contract: null,
        max_weight: "",
        type: null,
        editing: true,
      });
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async saveConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-weight-config", item);
        this.isLoading = false;
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async getList() {
      try {
        const { data } = await httpClient.post("/list-weight-config", {
          ...this.filters,
        });
        this.totalPage = data.totalPage;
        this.totalItem = data.total;
        this.items = [...data.rows].map((row) => ({
          ...row,
          editing: false,
        }));
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
